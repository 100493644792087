import { gql } from '@apollo/client'

export const MESSAGE = gql`
  query Message($id: ID!, $idType: MessageIdType, $asPreview: Boolean) {
    message(id: $id, idType: $idType, asPreview: $asPreview) {
      id
      title
      content
      date
    }
  }
`

export const MESSAGES = gql`
  query Messages($where: RootQueryToMessageConnectionWhereArgs) {
    messages(where: $where) {
      nodes {
        id
        title
        content
        date
      }
      pageInfo {
        offsetPagination {
          total
        }
      }
    }
    categories: messageCategories(where: { hideEmpty: true }) {
      nodes {
        id
        name
      }
    }
  }
`
